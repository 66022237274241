<template>
  <label class="text-12 text-alert mt-3 mb-1">{{ label }} <slot></slot></label>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    label: String,
  },
};
</script>
