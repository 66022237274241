<template>
  <div>
    <FormLabel :label="label" :error="!!error" />
    <Textarea
      :id="id"
      :class="{ '!border-alert': !!error }"
      class="w-full"
      spellcheck="false"
      :name="addNameAttr ? name : undefined"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :label="label"
      :error="error"
      :maxlength="maxlength"
      @update:modelValue="updateValue"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
    />
    <label v-if="error || $slots['error']" class="text-12 text-alert mt-3 mb-1">{{ error }} <slot name="error"></slot></label>
  </div>
</template>

<script>
import ValidationMixin from '../../mixins/ValidationMixin';
import FormLabel from './FormLabel.vue';

export default {
  name: 'FormInput',
  components: { FormLabel },
  mixins: [ValidationMixin],
  emits: ['update:modelValue', 'blur', 'keyup'],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    readonly: Boolean,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: String,
    error: String,
    id: String,
    addNameAttr: {
      type: Boolean,
    },
    maxlength: {
      type: String,
    },
    placeholder: String,
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
