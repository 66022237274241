<template>
  <div>
    <FormLabel v-if="label" :label="label" :error="!!error" />
    <div class="w-full flex items-center relative">
      <Icon v-if="icon" class="w-4 h-4 flex-shrink-0 absolute left-2 opacity-40" :name="icon" />
      <Input
        :id="id ? id : null"
        :class="{ '!border-alert': !!error, 'pl-7': icon, 'pr-7': loading, 'hidearrow': hideArrow, 'text-center': textCenter }"
        ref="input"
        class="w-full"
        :name="addNameAttr ? name : undefined"
        :min="min"
        :max="max"
        :type="type"
        :disabled="disabled"
        :readonly="readonly"
        :modelValue="modelValue"
        :placeholder="placeholder"
        :label="label"
        :error="error"
        :maxlength="maxlength"
        @update:modelValue="updateValue"
        @blur="onBlur"
        @focus="onFocus"
        @keyup="$emit('keyup', $event)"
      />
      <Icon v-if="loading" class="w-4 h-4 flex-shrink-0 absolute right-2 opacity-50 animate-spin" name="loading_circle" />
    </div>
    <FormError v-if="error || $slots['error']" class="text-12 text-alert mt-3 mb-1">{{ error }} <slot name="error"></slot></FormError>
  </div>
</template>

<script>
import ValidationMixin from '../../mixins/ValidationMixin';
import FormLabel from './FormLabel.vue';
import FormError from './FormError.vue';

export default {
  name: 'FormInput',
  components: { FormLabel, FormError },
  mixins: [ValidationMixin],
  emits: ['update:modelValue', 'focus', 'blur', 'keyup'],
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    readonly: Boolean,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: String,
    error: String,
    min: String,
    max: String,
    id: String,
    icon: String,
    loading: Boolean,
    hideArrow: Boolean,
    textCenter: Boolean,
    addNameAttr: {
      type: Boolean,
    },
    maxlength: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  methods: {
    focus() {
      this.$refs.input.$el.focus();
    },
    updateValue(value) {
      this.eventbus.emit('input', value);
      this.$emit('update:modelValue', value);
    },
    onBlur($event) {
      this.eventbus.emit('blur', $event);
      this.$emit('blur', $event);
    },
    onFocus($event) {
      this.eventbus.emit('focus', $event);
      this.$emit('focus', $event);
    },
  },
};
</script>
<style>
input.hidearrow::-webkit-outer-spin-button,
input.hidearrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.hidearrow[type=number] {
  -moz-appearance: textfield;
}
</style>
