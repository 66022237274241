<template>
  <label
    class="checkbox flex flex-row items-center relative cursor-pointer text-14 min-h-5 select-none"
    :class="{
      'cursor-not-allowed opacity-50': disabled,
      'h-5 w-5': !indent,
      'pl-7': indent,
    }"
  >
    <input type="checkbox" :name="name" class="absolute opacity-0 h-0 w-0" :value="value" @change="updateInput" :checked="value" :disabled="disabled" />
    <div class="checkmark absolute top-0 left-0 h-5 w-5 bg-on-primary border border-stroke rounded-4" :class="{ 'border-primary border-opacity-40': value }"></div>
    <span v-if="label">{{ label }}</span>
    <slot />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  emits: ['update:modelValue'],
  props: {
    disabled: Boolean,
    modelValue: [Boolean],
    label: {
      type: String,
      default: '',
    },
    name: String,
  },
  data() {
    return {
      indent: false,
      value: false,
    };
  },
  created() {
    this.indent = !!this.label || !!this.$slots.default;
  },
  methods: {
    updateInput() {
      this.value = !this.value;
      this.$emit('update:modelValue', this.value);
    },
  },
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue !== this.value) {
          this.value = this.modelValue;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.checkbox:hover input ~ .checkmark {
  background-color: #ebebec;
}

.checkbox.opacity-50:hover input ~ .checkmark {
  background-color: #fafafa !important;
}

.checkbox input:checked ~ .checkmark {
  background-color: rgb(var(--color-primary));
}

.checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 6px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-radius: 2px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
