<template>
  <table v-if="headersComputed.length" class="data-table" :class="{ 'data-table--themed rounded-6 border border-solid border-stroke border-opacity-50 border-separate border-spacing-0': themed }">
    <thead :class="{ 'bg-surface-dark': themed }">
      <tr>
        <th v-if="!hideIndex" class="border-b border-gray-200 text-left" :class="{ 'px-2 py-1': themed }">{{ $t('index') }}</th>
        <th v-for="(header, index) in headersComputed" :key="`${header.value}-${index}`" class="border-b border-gray-200 text-left" :class="{ 'px-2 py-1 rounded-t-6 overflow-hidden': themed }">
          {{ header.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in value" :key="index">
        <td v-if="!hideIndex" :class="rowClass(index)">{{ index }}</td>
        <td v-for="(header, colIndex) in headersComputed" :key="colIndex" :class="rowClass(index)">
          {{ row[header.value] }}
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else>{{ $t('no_data_to_display') }}</div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    headers: { type: Array, default: () => [] },
    hideIndex: Boolean,
    themed: Boolean,
  },
  computed: {
    headersComputed() {
      if (this.headers && this.headers.length) {
        return this.headers;
      }
      return this.headersRaw;
    },
    headersRaw() {
      const [firstRow] = this.value;
      if (firstRow) {
        return Object.keys(firstRow);
      }
      return [];
    },
  },
  methods: {
    rowClass(index) {
      return {
        'px-2 py-0.5': this.themed,
        'border-b border-solid border-stroke border-opacity-50': this.themed && index !== this.value.length - 1,
      };
    },
  },
};
</script>
<style lang="scss">
.data-table:not(.data-table--themed) {
  white-space: nowrap;

  td,
  th {
    padding: 5px 10px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
</style>
