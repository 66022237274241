<template>
  <span class="relative inline-block flex-initial flex-col px-2 py-0.5 font-600 rounded-full text-center border border-gray-200">
    <span
      aria-hidden
      class="absolute inset-0 opacity-50 rounded-full"
      :class="{
        'bg-gray-200': gray,
        'bg-orange-300': warn,
        'bg-green-200': success,
        'bg-red-200': error,
        'bg-blue-200': info,
      }"
    ></span>
    <div
      class="whitespace-nowrap relative"
      :class="{
        'text-gray-900': gray,
        'text-orange-900': warn,
        'text-green-900': success,
        'text-red-900': error,
        'text-blue-800': info,
        'text-11': !small,
        'text-9': small,
      }"
    >
      {{ text }}
    </div>
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    text: String,
    warn: Boolean,
    success: Boolean,
    error: Boolean,
    info: Boolean,
    gray: Boolean,
    small: Boolean,
  },
};
</script>
